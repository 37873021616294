import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://parkadoapi.azurewebsites.net/api/bookings');
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Failed to fetch bookings. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredBookings = useMemo(() => {
    if (!startDate || !endDate) return bookings;

    return bookings.filter(booking => {
      const bookingStartDate = new Date(booking.startDate);
      const bookingEndDate = new Date(booking.endDate);
      return bookingStartDate >= startDate && bookingEndDate <= endDate;
    });
  }, [bookings, startDate, endDate]);

  const handleFilter = () => {
    console.log('Filtering with date range:', { startDate, endDate });
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="App" style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Booking Details</h1>
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
        <button onClick={handleFilter} style={{ padding: '0.5rem 1rem', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}>Apply Filter</button>
        <button onClick={handleClearFilter} style={{ padding: '0.5rem 1rem', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px' }}>Clear Filter</button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <table style={{ minWidth: '100%', borderCollapse: 'collapse', backgroundColor: 'white', border: '1px solid #ddd' }}>
          <thead style={{ backgroundColor: '#f2f2f2' }}>
            <tr>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>ID</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>First Name</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Last Name</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Email</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Phone</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Car Plate</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Start Date</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>End Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings.map((booking) => (
              <tr key={booking.id} style={{ ':hover': { backgroundColor: '#f5f5f5' } }}>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.id}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.firstName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.lastName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.email || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.phone || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.carPlate || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.startDate ? new Date(booking.startDate).toLocaleString() : 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.endDate ? new Date(booking.endDate).toLocaleString() : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;